@import "material";

@import "main";

@import "utils";

html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;

  .cdk-overlay-dark-backdrop {
    background: rgb(0 0 0 / 82%);
  }
}
