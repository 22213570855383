// Colors

$base-layout-color: #151c26;
$popover-layout-color: #233446;
$background-block: #1b2936;

$background-modal-block: #233446;
$background-header-block: #233446;

$base-gray: rgba(50, 62, 74, .9);
$base-gray-not-transparent: rgba(50, 62, 74, 1);
$light-gray: #445464;
$middle-light-gray: #949fb7;
$extra-light-gray: #ebeef4;
$middle-light-white: #fafafa;

$orange: #ff6f00;
$red: #fe3535;
$gray: #d8d8d8;
$light-black: #1b232e;
$base-black: #151c26;
$middle-black: #202f3d;
$green: #009440;
$middle-green: #00a55f;

$white: rgb(255, 255, 255);
$black: rgb(0, 0, 0);

$middle-light-blue: #61a9ff;
$light-blue: #27a6fe;
$blue: #269cee;
$dark: #1b2936;
$dark-blue: rgba(39, 166, 254, .5);
$color-primary: #27a6fe;
$colorF1: #f1f1f1;
$color35: #353535;
$danger: #a94442;

// Fonts

$extra-small-font-size: 10px;
$small-font-size: 12px;
$base-font-size: 14px;
$large-font-size: 16px;

$base-line-height: 19px;

// Indents

$base-indent: 15px;

// Width

$page-max-width: 1170px + (2 * $base-indent);

// Screen breakpoints

$breakpoint-1024: 1024px;

$breakpoint-1160: 1160px;

$breakpoint-768: 768px;
$breakpoint-mb: 576px;

// for media
$mobiles: "screen and (min-width: 0) and (max-width: 680px)";
$tablets: "screen and (min-width: 681px) and (max-width: 1024px)";
$small-laptop: "screen and (min-width: 1024px) and (max-width: 1500px)";
$laptop: "screen and (min-width: 768px)";

// Other

$base-border-radius: 5px;

// Font weight
$fontWeightThin: 200;
$fontWeightLight: 300;
$fontWeightNormal: 400;
$fontWeightSemiBoldLight: 500;
$fontWeightSemiBold: 600;
$fontWeightDark: 600;
$fontWeightBold: 700;
$fontWeightExtra: 800;
$fontWeightBlack: 900;
