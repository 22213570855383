@import "variables";

@import "mixins";

.m--0 {
  margin: 0;
}

.p--0 {
  padding: 0;
}

.p--15 {
  padding: 15px;
}

.mt--5 {
  margin-top: 5px;
}

.mt--10 {
  margin-top: 10px;
}

.mt--15 {
  margin-top: 15px;
}

.mt--18 {
  margin-top: 18px;
}

.mt--20 {
  margin-top: 20px;
}

.mt--30 {
  margin-top: 30px;
}

.mt--40 {
  margin-top: 40px;
}

.mt--50 {
  margin-top: 50px;
}

.mb--0 {
  margin-bottom: 0;
}

.mb--5 {
  margin-bottom: 5px;
}

.mb--10 {
  margin-bottom: 10px;
}

.mb--15 {
  margin-bottom: 15px;
}

.mb--20 {
  margin-bottom: 20px;
}

.mb--30 {
  margin-bottom: 30px;
}

.mb--40 {
  margin-bottom: 40px;
}

.ml--5 {
  margin-left: 5px;
}

.ml--10 {
  margin-left: 10px;
}

.ml--15 {
  margin-left: 15px;
}

.ml--20 {
  margin-left: 20px;
}

.ml--100 {
  margin-left: 100px;
}

.mr--5 {
  margin-right: 5px;
}

.mr--10 {
  margin-right: 10px;
}

.mr--15 {
  margin-right: 15px;
}

.mr--17 {
  margin-right: 17px;
}

.mr--20 {
  margin-right: 20px;
}

.mr--30 {
  margin-right: 30px;
}

.pt--5 {
  padding-top: 5px;
}

.pt--10 {
  padding-top: 10px;
}

.pt--15 {
  padding-top: 15px;
}

.pt--20 {
  padding-top: 20px;
}

.pb--5 {
  padding-bottom: 5px;
}

.pb--10 {
  padding-bottom: 10px;
}

.pb--15 {
  padding-bottom: 15px;
}

.pb--20 {
  padding-bottom: 20px;
}

.pb--40 {
  padding-bottom: 40px;
}

.pl--5 {
  padding-left: 5px;
}

.pl--10 {
  padding-left: 10px;
}

.pl--15 {
  padding-left: 15px;
}

.pl--20 {
  padding-left: 20px;
}

.pr--5 {
  padding-right: 5px;
}

.pr--10 {
  padding-right: 10px;
}

.pr--15 {
  padding-right: 15px;
}

.pr--20 {
  padding-right: 20px;
}

.flex--1 {
  flex: 1;
}

.flex--0 {
  flex: 0;
}

.pointer {
  cursor: pointer;
}

.text-center {
  text-align: center;
}

.w--100 {
  width: 100px;
}

.w--270 {
  width: 270px;
}

.w-10 {
  width: 10%;
}

.w-18 {
  width: 18%;
}

.w-20 {
  width: 20%;
}

.w-40 {
  width: 40%;
}

.w-70 {
  width: 70%;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.inline {
  display: inline-block;
}

.decoration-none {
  text-decoration: none;
}

.small-text {
  font-size: $small-font-size;
}

.with-base-border-radius {
  border-radius: $base-border-radius;
}

.sticky-block {
  position: fixed;
}

.sticky-bottom-block {
  bottom: 0;

  @extend .sticky-block;
}

.color--light-blue {
  color: $light-blue;
}

.color--blue {
  color: $blue;
}

.color--white {
  color: $white;
}

.ml7 {
  margin-left: 7%;
}

.ml10 {
  margin-left: 10%;
}

.ml15 {
  margin-left: 15%;
}

.ml20 {
  margin-left: 20%;
}

.ml30 {
  margin-left: 30%;
}

.mr10 {
  margin-right: 10%;
}

.mr15 {
  margin-right: 15%;
}

.mr20 {
  margin-right: 20%;
}
