@import "variables";

@import "~@angular/material/theming";

$custom-typography: mat-typography-config($font-family: '"Open Sans", sans-serif;');

@include mat-core($custom-typography);

.mat-form-checkbox-field {
  padding-bottom: 1.25em;
}

.mat-spinner.spinner--white circle {
  stroke: #fff;
}

.material-element--squeezed {
  margin-left: $base-indent * 1.6;
  margin-right: $base-indent * 1.6;
}

.mat-form-field-label {
  color: $middle-light-gray !important;
  font-size: inherit;
}

.mat-icon-button {
  color: #c1c8d7;
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(164, 170, 179, .5);
  height: 1px;
}

.mat-calendar-body-selected {
  background-color: rgba(164, 170, 179, .5);
}

.mat-option:focus:not(.mat-option-disabled),
.mat-option:hover:not(.mat-option-disabled) {
  background: $light-blue;
  color: $white;
}

.mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: $light-blue;
  color: $white;
}

.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: $light-blue;
  color: $white;
}

.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: $white;
}

.mat-select-value {
  color: $white;
}

.mat-select-arrow {
  color: $white !important;
}

.mat-form-field-appearance-legacy .mat-form-field-infix {
  border: 0;
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: transparent !important;
}

.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-position: 0;
  background-color: rgba(164, 170, 179, .5) !important;
  height: 1px;
  background-image: none;
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-dialog-container {
  padding: 0 !important;
  color: inherit;
  overflow: inherit !important;
  background-color: $dark;
  border-radius: 5px !important;
}

.mat-follow {
  background-color: #269cee;
  color: white;
}

.mat-unfollow {
  background-color: #415f7f;
  color: white;
}

.mat-icon {
  cursor: pointer;
}
