@import "variables";

html,
body {
  min-height: 100%;
  margin: 0;
  font-family: "Open Sans";
  font-size: $base-font-size;
  line-height: normal;
  color: $white;
}

html {
  height: 100%;
}

body {
  height: 100%;
  background-color: $base-layout-color;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

input,
label,
select,
button,
textarea {
  margin: 0;
  border: 0;
  padding: 0;
  display: inline-block;
  vertical-align: middle;
  white-space: normal;
  background: none;
  line-height: 1;

  /* Browsers have different default form fonts */
  font-family: "Open Sans";
  font-size: $base-font-size;
}

/* Remove the stupid outer glow in Webkit */
input:focus,
button:focus,
textarea:focus {
  outline: 0;
}

/* Make webkit render the search input like a normal text field */
input[type="search"] {
  -webkit-appearance: textfield;
  -webkit-box-sizing: content-box;
}

/* Turn off the recent search for webkit. It adds about 15px padding on the left */
::-webkit-search-decoration {
  display: none;
}

/* IE8 and FF freak out if this rule is within another selector */
::-webkit-file-upload-button {
  padding: 0;
  border: 0;
  background: none;
}

.link {
  color: $white;
  text-decoration: none;
  font-weight: 400;
}

// Override flexboxgrid library cols gap
.row {
  margin-left: -$base-indent;
  margin-right: -$base-indent;

  [class^="col-"] {
    padding-left: $base-indent;
    padding-right: $base-indent;
  }
}

.toast-container {

  .ngx-toastr,
  .ngx-toastr:hover {
    box-shadow: none;
  }
}

/* Not apply Webkit for the submit type of button */
[type="submit"] {
  -webkit-appearance: none;
}

/* The emerging W3C standard
   that is currently Firefox-only */
* {
  box-sizing: border-box;
  scrollbar-width: thin;
  scrollbar-color: $middle-light-gray;
}

/* Works on Chrome/Edge/Safari */
*::-webkit-scrollbar {
  width: 5px;
  height: 0;
}

*::-webkit-scrollbar-thumb {
  background-color: $middle-light-gray;
  border: 1px solid $middle-light-gray;
  border-radius: 5px;
}

.ps .ps__rail-x:hover,
.ps .ps__rail-y:hover,
.ps .ps__rail-x:focus,
.ps .ps__rail-y:focus,
.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-y.ps--clicking {
  background: none !important;
  opacity: .9;
}

.ps__rail-x,
.ps__rail-y {
  z-index: 1;
}
